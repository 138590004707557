import request from "./request";

/**
 * 【标准版交易组件】代码
 */

/**
 * 获取授权链接
 */
export const authUrl = () => request.get("/code/auth");

/**
 * 提交代码
 */
export const upload = () => request.post(`/code/upload`);

/**
 * 获取状态
 */
export const getStatus = () => request.get(`/code/status`);
