<template>
  <div class="code-page">
    <a-spin v-if="loading" />
    <template v-else>
      <a-steps :current="current" size="small">
        <a-step title="授权" />
        <a-step title="发布" />
        <a-step title="审核" />
        <a-step title="完成" />
      </a-steps>
      <div style="height:30px"></div>

      <!-- 1. 授权 -->
      <div v-if="current == 0" class="app-step">
        <h2><a-icon type="info-circle" /> 小程序标准版交易组件授权</h2>

        <p>仅适用于：个体户和企业注册的小程序</p>
        <p>小程序需要：提前开通标准版交易组件，并完成开店任务</p>
        <div class="">
          <a-button :href="redirectUrl" type="primary" size="large" block>
            <a-icon type="qrcode" />
            点击开始授权
          </a-button>
        </div>
      </div>
      <!-- 2. 提交代码提交审核 -->
      <div v-if="current == 1" class="app-step">
        <h2><a-icon type="rocket" /> 上传/提审代码</h2>

        <p>1、上传小程序代码</p>
        <p>2、自动提交给微信官方审核</p>

        <div class="">
          <a-button @click="upload" type="primary" size="large" block>
            <a-icon type="upload" />
            上传并提审代码
          </a-button>
        </div>
      </div>
      <!-- 3. 等待审核 -->
      <div v-if="current == 2" class="app-step">
        <h2><a-icon type="hourglass" /> 等待审核</h2>

        <p>1、已上传代码并提交审核，请耐心等待微信官方审核通过</p>
        <p>
          2、小程序管理员微信会收到审核结果，审核通过后大概 5
          分钟后，小程序可以正常使用
        </p>
        <p>3、如果小程序没有更新，请删除微信缓存，再重新打开</p>
      </div>
      <!-- 3. 完成 -->
      <div v-if="current == 3" class="app-step">
        <h2><a-icon type="check-circle" /> 发布成功</h2>

        <p>小程序代码已经发布成功</p>
        <p>1、审核通过后大概 5 分钟后，小程序可以正常使用</p>
        <p>2、如果小程序没有更新，请删除微信缓存，再重新打开</p>
      </div>
    </template>
  </div>
</template>
<script>
const CodeSvc = require("@/service/code");

export default {
  name: "Code",
  data() {
    return {
      loading: true,
      current: 0,
      redirectUrl: "https://api.xinsailei.com/msa/code_auth",
    };
  },

  methods: {
    async load() {
      const { status } = await CodeSvc.getStatus();
      this.current = status;

      this.loading = false;
    },

    async upload() {
      await CodeSvc.upload();
      this.current = 2; // 切换状态
    },
  },

  mounted() {
    // this.current = 0;
    this.load();
  },
};
</script>
<style lang="scss">
.code-page {
  max-width: 400px;
  margin: 30px auto;
  padding: 10px;

  .app-step {
    padding: 20px;
    background: #eeee;
    margin: -10px;
    border-radius: 20px;
  }

  h2 {
    color: blue;
    text-align: center;
  }
}
</style>
